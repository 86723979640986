import { PostCategoryCreationBody, PostCategoryQueryParams, PostCategoryUpdatingBody } from '@models/news';
import { fetchData } from '@pkg/ui';
import { withAuth } from '@pkg/utils';
import urlcat from 'urlcat';

export const PostCategoriesApi = () => {
  return {
    async create(data: PostCategoryCreationBody) {
      return await fetchData(withAuth({ endpoint: 'admin/post-categories', method: 'POST', body: data }));
    },
    async get(query: PostCategoryQueryParams) {
      return await fetchData(withAuth({ endpoint: 'admin/post-categories', query }));
    },
    async getById(id: string) {
      return await fetchData(withAuth({ endpoint: urlcat('admin/post-categories/:id', { id }) }));
    },
    async update(id: string, data: PostCategoryUpdatingBody) {
      return await fetchData(
        withAuth({ endpoint: urlcat('admin/post-categories/:id', { id }), method: 'PUT', body: data })
      );
    },
    async restore(ids: string[]) {
      return await fetchData(
        withAuth({ endpoint: urlcat('admin/post-categories/restore', {}), method: 'PATCH', body: { ids } })
      );
    },
    async remove(ids: string[]) {
      return await fetchData(
        withAuth({ endpoint: urlcat('admin/post-categories/remove', {}), method: 'PATCH', body: { ids } })
      );
    },
    async delete(ids: string[]) {
      return await fetchData(
        withAuth({ endpoint: urlcat('admin/post-categories/delete', {}), method: 'DELETE', body: { ids } })
      );
    },
  };
};
