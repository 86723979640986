import { cn, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { Button, Checkbox, Col, Row, message } from 'antd';
import { cloneDeep, filter, isFunction } from 'lodash';
import { useEffect, useState } from 'react';
import { AcnPortal } from '../components';
import { useTrans } from '../hooks';
import ListImage from './ListImage';
import { FileApi } from './api';

interface MediaManagerProps {
  onSelect?: (images: any[]) => void;
  multiple?: boolean;
  module: string;
}

const MediaManager = ({ onSelect, multiple = false, module }: MediaManagerProps) => {
  const fileApi = FileApi();
  const { t } = useTrans();
  const [files, setFiles] = useState<any[]>([]);
  const [pages, setPages] = useState({});
  const [selected, setSelected] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getFile();
  }, []);

  const handlePageChange = async (page: number) => {
    getFile({ page });
  };

  const getFile = async (query = { page: 1 }) => {
    const res = await fileApi.get(query);
    if (res.error) return message.error(res.error.message);
    setFiles(res.data.data);
    setPages(res.data.pages);
    setSelected([]);
  };

  const handleSelected = (id: string) => {
    let fileSelected = cloneDeep(selected);
    if (multiple) {
      const index = fileSelected.indexOf(id);
      if (index > -1) fileSelected.splice(index, 1);
      else fileSelected.push(id);
    } else fileSelected = [id];
    if (isFunction(onSelect)) {
      const listFile = filter(files, (file: any) => fileSelected.includes(file._id));
      onSelect(listFile);
    }
    setSelected(fileSelected);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleRemoveFile = async (id: string) => {
    const data = { ids: JSON.stringify([id]) };
    const res = await fileApi.delete(data);
    if (res.error) return showErrorNoti(res.error.message);
    showSuccessNoti(res.data);
    getFile();
  };

  const handleRemoveFiles = async () => {
    const data = { ids: JSON.stringify(selected) };
    const res = await fileApi.delete(data);
    if (res.error) return showErrorNoti(res.error.message);
    showSuccessNoti(res.data);
    getFile();
  };

  const handleSelectAll = (e: any) => {
    if (e.target.checked) {
      const ids = files.map((file: any) => file._id);
      if (isFunction(onSelect)) {
        const listFile = filter(files, (file: any) => ids.includes(file._id));
        onSelect(listFile);
      }
      return setSelected(ids);
    }
    if (isFunction(onSelect)) onSelect([]);
    setSelected([]);
  };

  const renderToolbar = () => {
    return (
      <div className={cn('media-toolbar', 'flex bg-gray-100 px-4 py-2')}>
        <div className="space-x-2">
          <AcnPortal target="#cn-admin-translate">
            <Button className="text-white bg-red-500" disabled={selected.length === 0} onClick={handleRemoveFiles}>
              {t('deleteBtn')}
            </Button>
          </AcnPortal>
        </div>
        <div className="flex-grow" />
        {multiple && (
          <div>
            <Checkbox checked={selected.length > 0 && selected.length === files.length} onClick={handleSelectAll}>
              {t('selectAll')}
            </Checkbox>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {/* {!onSelect && <ToolBar type={'active'} name="Hình ảnh" module={module} />} */}

      <div className={'main-content'}>
        <div className="media-content">
          <Row gutter={[16, 16]}>
            <Col flex="100%">
              <div className={cn('media-right h-[calc(100vh-230px)] shadow-default')}>
                {/* {renderToolbar()} */}
                <ListImage
                  files={files}
                  getFile={getFile}
                  selected={selected}
                  onSelected={handleSelected}
                  onRemove={handleRemoveFile}
                />
              </div>
              {/* <CustomPagination pages={pages} onChange={handlePageChange} classNames={{ root: 'bg-white py-2' }} /> */}
            </Col>
          </Row>
        </div>
      </div>
      {/* {openModal && <FormModal open={openModal} config={modalConfig} onClose={closeModal} />} */}
    </>
  );
};

export default MediaManager;
