import { FileQueryParams } from '@models/base';
import { withAuth } from '@pkg/utils';
import { fetchData } from '../hooks';

export const FileApi = () => {
  return {
    async get(query: FileQueryParams) {
      return await fetchData(withAuth({ endpoint: 'files', method: 'GET', query }));
    },
    async delete(data: any) {
      return await fetchData(withAuth({ endpoint: 'files', method: 'DELETE', body: data }));
    },
    async upload(data: any) {
      return await fetchData(withAuth({ endpoint: 'files', method: 'POST', body: data, isUpload: true }));
    },
  };
};
