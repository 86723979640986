import { websiteConfigs } from '@pkg/config';
import { userToken } from '@pkg/constants';
import urlcat from 'urlcat';

export const getErrorMessage = (e: any) => {
  return e.message ?? e;
};

export type FetchDataProps = {
  endpoint: string;
  query?: any;
  hasAuthen?: boolean;
  options?: any;
  body?: any;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  tokenType?: string;
  isUpload?: boolean;
};

export const fetchData = async ({
  endpoint,
  query,
  hasAuthen,
  options,
  body,
  method,
  tokenType = userToken,
  isUpload,
}: FetchDataProps) => {
  try {
    const url = urlcat(`${websiteConfigs.apiUrl}/api/${endpoint}`, query);
    const opts: RequestInit = body
      ? { body: !isUpload ? JSON.stringify(body) : body, method: method || 'POST' }
      : { method: method || 'GET' };
    opts.headers = !isUpload ? { 'Content-Type': 'application/json' } : {};

    if (hasAuthen) {
      const token = localStorage.getItem(tokenType);
      opts.headers.Authorization = `Bearer ${token}`;
    }

    const res = await fetch(url, opts);
    const rs = await res.json();

    if (rs.statusCode === 401 && url.indexOf('login') === -1) {
      localStorage.removeItem(tokenType);
      window.location.href = '/login';
      return { error: rs.error ? rs.error : { message: rs.message, statusCode: rs.statusCode } };
    } else if (rs.statusCode >= 400 && rs.statusCode <= 500) {
      return { error: rs.error ? rs.error : { message: rs.message, statusCode: rs.statusCode } };
    }

    return rs;
  } catch (error) {
    console.error(options?.message ?? getErrorMessage(error));
  }
};
