import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type TPortal = {
  target: string;
  children: ReactNode;
};

export const AcnPortal = ({ target, children }: TPortal) => {
  const [targetDom, setTargetDom] = useState<Element>();

  useEffect(() => {
    const elm = document.querySelector(target);
    if (elm) setTargetDom(elm);
  }, []);

  return targetDom ? createPortal(children, targetDom) : <></>;
};
