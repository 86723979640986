import { PostCategory, PostCategoryCreationBody } from '@models/news';
import {
  AcnButton,
  AcnCard,
  AutoForm,
  BreadcrumbItem,
  DropdownOptions,
  baseButton,
  baseScrollbar,
  getModelValues,
  useFormModel,
  useTrans,
} from '@pkg/ui';
import { cn, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { useQuery } from '@tanstack/react-query';
import { cloneDeep, set } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Toolbar } from '../../../base/components';
import { getModule } from '../../../base/helpers/utils';
import { useHotkey } from '../../../base/hooks';
import { PostCategoriesApi } from './api';
import { PostCategoryEditModel } from './edit.model';

export const EditPostCategoryContainer = () => {
  const { t } = useTrans();
  const router = useRouter();
  const postCategoriesApi = PostCategoriesApi();

  const id = router.query.id as string;

  const { data } = useQuery<PostCategory>({
    queryKey: ['post-category', id],
    queryFn: () => postCategoriesApi.getById(id).then((rs) => rs.data),
    enabled: !!id,
  });

  const formModel = useFormModel(PostCategoryEditModel.model, {});

  const form = useForm<PostCategoryCreationBody>({
    mode: 'all',
    defaultValues: getModelValues(PostCategoryEditModel.model),
  });

  const { data: categories } = useQuery<PostCategory[]>({
    queryKey: ['post-categories'],
    queryFn: () => {
      return postCategoriesApi.get({}).then((rs) => {
        if (!rs.data) return [];
        return rs.data.filter((r: PostCategory) => !r.parent && r._id !== id);
      });
    },
    enabled: !!formModel.model,
    initialData: () => [],
  });

  useEffect(() => {
    if (data) {
      form.reset({ ...data, parent: data.parent?._id });
    }
  }, [data]);

  useEffect(() => {
    if (categories && formModel.model) {
      const model = cloneDeep(formModel.model);
      const options: DropdownOptions[] = categories.map((r) => ({ label: r.title, value: r._id }));
      set(model, 'parent.options', options);
      formModel.setModel(model);
    }
  }, [categories]);

  const breadcrumb: BreadcrumbItem[] = [
    { title: t('dashboard.title'), href: '/' },
    { title: t('postCategories.title'), href: '/' + getModule(router) },
    { title: t('postCategories.editTitle') },
  ];

  const onUpdate = async (data: PostCategoryCreationBody) => {
    const res = await postCategoriesApi.update(id, data);
    if (!res.data) return showErrorNoti(res.error.message);
    showSuccessNoti(t('updateSuccess'));
    router.push('/' + getModule(router));
  };

  const onUpdateAndPublic = async (data: PostCategoryCreationBody) => {
    const res = await postCategoriesApi.update(id, { ...data, active: true });
    if (!res.data) return showErrorNoti(res.error.message);
    showSuccessNoti(t('updateSuccess'));
    router.push('/' + getModule(router));
  };

  const onUpdateAndUnpublic = async (data: PostCategoryCreationBody) => {
    const res = await postCategoriesApi.update(id, { ...data, active: false });
    if (!res.data) return showErrorNoti(res.error.message);
    showSuccessNoti(t('updateSuccess'));
    router.push('/' + getModule(router));
  };

  useHotkey('ctrl+s', form.handleSubmit(onUpdate));

  return (
    <div className="space-y-3">
      <Toolbar
        items={breadcrumb}
        moreActions={
          <>
            <AcnButton
              disabled={!form.formState.isValid}
              className={cn(baseButton, '!bg-blue-500 !text-white')}
              onClick={form.handleSubmit(onUpdate)}
            >
              {t('editBtn')}
            </AcnButton>
            {!data?.active && (
              <AcnButton
                disabled={!form.formState.isValid}
                className={cn(baseButton, '!bg-blue-500 !text-white')}
                onClick={form.handleSubmit(onUpdateAndPublic)}
              >
                {t('editAndPublicBtn')}
              </AcnButton>
            )}
            {data?.active && (
              <AcnButton
                disabled={!form.formState.isValid}
                className={cn(baseButton, '!bg-blue-500 !text-white')}
                onClick={form.handleSubmit(onUpdateAndUnpublic)}
              >
                {t('editAndUnpublicBtn')}
              </AcnButton>
            )}
          </>
        }
      />
      <div className={cn('md:flex gap-3 h-[calc(100vh-175px)] space-y-3 md:space-y-0', baseScrollbar)}>
        <FormProvider {...form}>
          <div className={cn('flex-1 space-y-3')}>
            <AcnCard>
              <AcnCard.Title>{t('details')}</AcnCard.Title>
              <AcnCard.Content className="min-h-[calc(100vh-230px)]">
                <div className="space-y-4">
                  <AutoForm
                    model={formModel.model}
                    fields={['title', 'slug', 'description', 'content', 'seoTitle', 'seoDescription']}
                    setModel={formModel.setModel}
                  />
                </div>
              </AcnCard.Content>
            </AcnCard>
          </div>

          <div className="md:w-[400px] w-full space-y-3">
            <AcnCard>
              <AcnCard.Title>{t('publish')}</AcnCard.Title>
              <AcnCard.Content className="space-y-4">
                <AutoForm model={formModel.model} fields={['parent', 'image']} setModel={formModel.setModel} />
              </AcnCard.Content>
            </AcnCard>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};
