import { Member, MemberQueryParams } from '@models/base';
import { BreadcrumbItem, useQueryFilter, useTrans } from '@pkg/ui';
import { formatDate, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { useQuery } from '@tanstack/react-query';
import { TableColumnsType } from 'antd';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { SearchBox, Toolbar } from '../../components';
import { AdminDataTable, getSortOrder } from '../../components/AdminDataTable';
import { getModule } from '../../helpers/utils';
import { MemberApi } from './api';

export const MemberContainer = () => {
  const { t } = useTrans();
  const memberApi = MemberApi();
  const router = useRouter();
  const breadcrumb: BreadcrumbItem[] = [{ title: t('dashboard.title'), href: '/' }, { title: t('member.title') }];
  const [selected, setSelected] = useState<string[]>([]);
  const { filter, setFilter } = useQueryFilter<MemberQueryParams>('members', { deleted: false });

  const { data, refetch } = useQuery({
    queryKey: ['members', filter],
    queryFn: () => memberApi.get(filter).then((rs) => rs.data || []),
    initialData: () => [],
  });

  useEffect(() => {
    setSelected([]);
  }, [filter.deleted]);

  const onCreate = () => {
    const module = getModule(router);
    router.push(`/${module}/create`);
  };

  const onUpdate = (row: Member) => {
    const module = getModule(router);
    router.push(`/${module}/${row._id}/edit`);
  };

  const onRestore = async (row?: Member) => {
    if (row) {
      const res = await memberApi.restore([row._id]);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('restoreSuccess', { name: row.username }));
    } else {
      const res = await memberApi.restore(selected);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('restoreItemsSuccess', { count: selected.length }));
      setSelected([]);
    }

    refetch();
  };

  const onRemove = async (row?: Member) => {
    if (row) {
      const res = await memberApi.remove([row._id]);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteSuccess', { name: row.username }));
    } else {
      const res = await memberApi.remove(selected);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteItemsSuccess', { count: selected.length }));
      setSelected([]);
    }
    refetch();
  };

  const onDelete = async (row?: Member) => {
    if (row) {
      const res = await memberApi.delete([row._id]);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteSuccess', { name: row.username }));
    } else {
      const res = await memberApi.delete(selected);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteItemsSuccess', { count: selected.length }));
      setSelected([]);
    }
    refetch();
  };

  const columns: TableColumnsType<Member> = [
    { title: t('member.fields.fullName.label'), dataIndex: 'fullName' },
    { title: t('member.fields.username.label'), dataIndex: 'username' },
    { title: t('member.fields.email.label'), dataIndex: 'email' },
    {
      title: t('dataTable.createdDate'),
      dataIndex: 'created',
      render: (value, row: Member) => formatDate(row.created),
      width: 200,
      sortOrder: getSortOrder<Member>('created', filter),
      sorter: true,
    },
    {
      title: t('dataTable.updatedDate'),
      dataIndex: 'updated',
      render: (value, row: Member) => formatDate(row.updated),
      width: 200,
      sortOrder: getSortOrder<Member>('updated', filter),
      sorter: true,
    },
  ];

  return (
    <div className="space-y-3">
      <Toolbar
        deleted={filter.deleted}
        items={breadcrumb}
        onCreate={onCreate}
        onRestore={onRestore}
        onRemove={onRemove}
        onDelete={onDelete}
        setFilter={setFilter}
        selected={selected}
        moreActions={<SearchBox onSearch={(keyword) => setFilter({ keyword })} keyword={filter.keyword} />}
      />

      <div className="bg-secondary p-4 h-[calc(100vh-178px)] overflow-y-auto">
        <AdminDataTable<Member>
          data={data?.data || []}
          pages={data?.pages}
          setFilter={setFilter}
          deleted={filter.deleted}
          columns={columns}
          onEdit={onUpdate}
          onRestore={onRestore}
          onRemove={onRemove}
          onDelete={onDelete}
          onSelected={setSelected}
          rowsSelected={selected}
        />
      </div>
    </div>
  );
};
