import { CopyOutlined, DeleteOutlined, DownloadOutlined, ZoomInOutlined } from '@ant-design/icons';
import { websiteConfigs } from '@pkg/config';
import { cn, showSuccessNoti } from '@pkg/utils';
import { Col, Row, Tooltip } from 'antd';
import React, { useState } from 'react';
import { AcnInputCopy } from '../autoForm/inputType/InputCopy';
import { scrollbarStyle } from '../classHelpers';
import { AcnImage, AcnLink } from '../components';
import DraggerUpload from './DraggerUpload';

type ListImageProps = {
  files: any[];
  getFile: any;
  selected: any;
  onSelected: any;
  onRemove: any;
};

const ListImage = React.memo<ListImageProps>(({ files, getFile, selected, onSelected, onRemove }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [imagePreview, setImagePreview] = useState<any>({});

  const handleDownloadFile = (image: any) => {
    const link = document.createElement('a');
    link.href = image.url;
    link.download = image.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCopy = (url: string) => {
    navigator.clipboard.writeText(url);
    showSuccessNoti('Copied!');
  };

  const handleOpen = (image: any) => {
    setOpenDrawer(true);
    setImagePreview(image);
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const drawerConfig = {
    title: 'Xem hình ảnh',
    cancelLabel: 'Đóng',
  };

  const renderImagePreview = () => {
    return (
      <div className="space-y-4 image-preview">
        <AcnImage src={imagePreview.url} alt={imagePreview.name} className="max-w-[50%] m-auto block" />
        <Row gutter={[16, 16]}>
          <Col flex="0 0 50%" className="space-y-1">
            <label className="font-semibold">Name:</label>
            <div>{imagePreview.name}</div>
          </Col>
          <Col flex="0 0 50%" className="space-y-1">
            <label className="font-semibold">Type:</label>
            <div>{imagePreview.type}</div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col flex="0 0 50%" className="space-y-1">
            <label className="font-semibold">Size:</label>
            <div>{(imagePreview.size / 1024).toFixed(2)}KB</div>
          </Col>
          <Col flex="0 0 50%" className="space-y-1">
            <label className="font-semibold">Upload at:</label>
            <div>{new Date(imagePreview.created).toLocaleDateString()}</div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col flex="0 0 50%" className="space-y-1">
            <label className="font-semibold">Url:</label>
            <div>
              <AcnInputCopy value={imagePreview.url} onChange={() => {}} />
            </div>
          </Col>
          <Col flex="0 0 50%" className="space-y-1">
            <label className="font-semibold">Full Url:</label>
            <div>
              <AcnInputCopy value={websiteConfigs.apiUrl + imagePreview.url} onChange={() => {}} />
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className={cn('media-right-body', 'space-y-4')}>
      <DraggerUpload onGetFile={getFile} />
      <div
        className={cn(
          'h-[calc(100vh-375px)] overflow-x-hidden grid grid-cols-[repeat(auto-fit,160px)] gap-4 auto-rows-max',
          scrollbarStyle
        )}
      >
        {files?.map((image: any) => {
          return (
            <div
              key={`col-${image._id}`}
              className={cn('media-browser-item group/item', 'relative text-primary rounded-sms cursor-pointer')}
            >
              <div className={cn('media-browser-item-preview')} onClick={() => onSelected(image._id)}>
                <AcnImage
                  src={image.url}
                  alt={image.filename}
                  width={350}
                  height={350}
                  className={cn('!w-full border rounded-sm h-[160px] object-cover', {
                    'border-primary border-[2px]': selected.includes(image._id),
                  })}
                />
              </div>
              <div
                className={cn(
                  'media-browser-item-info text-center',
                  'px-1 overflow-hidden overflow-ellipsis text-base whitespace-nowrap'
                )}
              >
                {image.name}
              </div>
              {selected.includes(image._id) && (
                <AcnLink
                  href="#"
                  className={cn(
                    'media-browser-select',
                    'absolute bg-white border border-solid border-green-500 text-green-500 top-2 left-2 w-6 h-6 rounded-sm'
                  )}
                  onClick={() => onSelected(image._id)}
                >
                  <span
                    className={cn(
                      'absolute left-[7px] top-[3px] w-2 h-3',
                      'border-[2px] border-primary border-t-0 border-l-0 rotate-45'
                    )}
                  />
                </AcnLink>
              )}

              <div className="hidden media-browser-item-action group-hover/item:block">
                <div className={cn('list-action', 'absolute top-2 right-2 flex flex-col space-y-2')}>
                  <Tooltip
                    placement="left"
                    title="Xem hình ảnh"
                    className="block w-5 h-5 p-1 bg-white rounded-sm shadow-md"
                  >
                    <ZoomInOutlined onClick={() => handleOpen(image)} />
                  </Tooltip>
                  <Tooltip
                    placement="left"
                    title="Tải xuống hình ảnh"
                    className="block w-5 h-5 p-1 !text-white bg-green-500 rounded-sm shadow-md"
                  >
                    <DownloadOutlined onClick={() => handleDownloadFile(image)} />
                  </Tooltip>
                  <Tooltip
                    placement="left"
                    title="Copy url của hình ảnh"
                    className="block w-5 h-5 p-1 !text-white bg-blue-500 rounded-sm shadow-md"
                  >
                    <CopyOutlined onClick={() => handleCopy(image.url)} />
                  </Tooltip>
                  <Tooltip
                    placement="left"
                    title="Xóa hình ảnh"
                    className="block w-5 h-5 p-1 !text-white bg-red-500 rounded-sm shadow-md"
                  >
                    <DeleteOutlined onClick={() => onRemove(image._id)} />
                  </Tooltip>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* {openDrawer && (
        <CustomDrawer width="40%" open={openDrawer} config={drawerConfig} onClose={handleClose}>
          {renderImagePreview()}
        </CustomDrawer>
      )} */}
    </div>
  );
});

export default ListImage;
