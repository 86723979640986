import { PostCategory, PostCategoryQueryParams } from '@models/news';
import { AcnImage, BreadcrumbItem, useQueryFilter, useTrans } from '@pkg/ui';
import { formatDate, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { useQuery } from '@tanstack/react-query';
import { TableColumnsType } from 'antd';
import { cloneDeep } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { SearchBox, Toolbar } from '../../../base/components';
import { AdminDataTable, getSortOrder } from '../../../base/components/AdminDataTable';
import { getModule, getRecordStatus } from '../../../base/helpers/utils';
import { PostCategoriesApi } from './api';

interface TreeCategory extends PostCategory {
  children?: PostCategory[];
}

export const PostCategoryContainer = () => {
  const { t } = useTrans();
  const postCategoriesApi = PostCategoriesApi();
  const router = useRouter();
  const breadcrumb: BreadcrumbItem[] = [
    { title: t('dashboard.title'), href: '/' },
    { title: t('postCategories.title') },
  ];
  const [selected, setSelected] = useState<string[]>([]);
  const { filter, setFilter } = useQueryFilter<PostCategoryQueryParams>('post-categories', { deleted: false });

  const { data, refetch } = useQuery({
    queryKey: ['post-categories', filter],
    queryFn: () =>
      postCategoriesApi.get(filter).then((rs) => {
        if (!rs.data) return [];

        const treeData: TreeCategory[] = cloneDeep(rs.data).filter((r: PostCategory) => !r.parent);
        if (!treeData.length) return rs.data;

        treeData.forEach((r) => {
          const children = rs.data.filter((i: PostCategory) => i.parent?._id === r._id);
          if (children.length) r.children = children;
        });
        return treeData;
      }),
    initialData: () => [],
  });

  useEffect(() => {
    setSelected([]);
  }, [filter.deleted]);

  const onCreate = () => {
    const module = getModule(router);
    router.push(`/${module}/create`);
  };

  const onUpdate = (row: PostCategory) => {
    const module = getModule(router);
    router.push(`/${module}/${row._id}/edit`);
  };

  const onRestore = async (row?: PostCategory) => {
    if (row) {
      const res = await postCategoriesApi.restore([row._id]);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('restoreSuccess', { name: row.title }));
    } else {
      const res = await postCategoriesApi.restore(selected);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('restoreItemsSuccess', { count: selected.length }));
      setSelected([]);
    }

    refetch();
  };

  const onRemove = async (row?: PostCategory) => {
    if (row) {
      const res = await postCategoriesApi.remove([row._id]);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteSuccess', { name: row.title }));
    } else {
      const res = await postCategoriesApi.remove(selected);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteItemsSuccess', { count: selected.length }));
      setSelected([]);
    }
    refetch();
  };

  const onDelete = async (row?: PostCategory) => {
    if (row) {
      const res = await postCategoriesApi.delete([row._id]);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteSuccess', { name: row.title }));
    } else {
      const res = await postCategoriesApi.delete(selected);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteItemsSuccess', { count: selected.length }));
      setSelected([]);
    }
    refetch();
  };

  const columns: TableColumnsType<PostCategory> = [
    { title: t('postCategories.fields.title.label'), dataIndex: 'title' },
    {
      title: t('postCategories.fields.image.label'),
      render: (row: PostCategory) => {
        return row.image ? <AcnImage src={row.image} alt="Error" width={48} height={27} /> : '-';
      },
      width: 120,
    },
    {
      title: t('dataTable.status'),
      dataIndex: 'active',
      render: (value, row: PostCategory) => t(getRecordStatus(row)),
      sortOrder: getSortOrder<PostCategory>('active', filter),
      sorter: true,
      width: 120,
    },
    {
      title: t('dataTable.createdDate'),
      dataIndex: 'created',
      render: (value, row: PostCategory) => formatDate(row.created),
      width: 200,
      sortOrder: getSortOrder<PostCategory>('created', filter),
      sorter: true,
    },
    {
      title: t('dataTable.updatedDate'),
      dataIndex: 'updated',
      render: (value, row: PostCategory) => formatDate(row.updated),
      width: 200,
      sortOrder: getSortOrder<PostCategory>('updated', filter),
      sorter: true,
    },
  ];

  return (
    <div className="space-y-3">
      <Toolbar
        deleted={filter.deleted}
        items={breadcrumb}
        onCreate={onCreate}
        onRestore={onRestore}
        onRemove={onRemove}
        onDelete={onDelete}
        setFilter={setFilter}
        selected={selected}
        moreActions={<SearchBox onSearch={(keyword) => setFilter({ keyword })} keyword={filter.keyword} />}
      />

      <div className="bg-secondary p-4 h-[calc(100vh-178px)] overflow-y-auto">
        <AdminDataTable<PostCategory>
          data={data}
          pages={{ current: 1, limit: 0, total: 1 }}
          setFilter={setFilter}
          deleted={filter.deleted}
          columns={columns}
          onEdit={onUpdate}
          onRestore={onRestore}
          onRemove={onRemove}
          onDelete={onDelete}
          onSelected={setSelected}
          rowsSelected={selected}
        />
      </div>
    </div>
  );
};
