import { Button, Modal } from 'antd';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import Media from './Media';

interface FileManagerProps {
  open: boolean;
  onClose: () => void;
  onInsert: (images: any) => void;
  multiple?: boolean;
}

export const FileManager = ({ open, onClose, onInsert, multiple = false }: FileManagerProps) => {
  const [selected, setSelected] = useState<string | string[]>([]);

  const handleSelectImage = (images: any[]) => {
    if (multiple) {
      const urls = images.map((image) => image.url);
      return setSelected(urls);
    }
    setSelected(images[0].url);
  };

  const handleInsert = () => {
    onInsert(selected);
  };

  return (
    <Modal
      centered
      width="100%"
      open={open}
      // onOk={onInsert}
      onCancel={onClose}
      maskClosable={false}
      title="File Manager"
      className="file-manager-modal"
      footer={[
        <Button key="back" onClick={onClose}>
          Close
        </Button>,
        <Button key="submit" disabled={isEmpty(selected)} type="primary" onClick={handleInsert}>
          Chèn hình ảnh
        </Button>,
      ]}
    >
      <Media multiple={multiple} onSelect={handleSelectImage} module={'media'} />
    </Modal>
  );
};
