import { AdminLayout } from '../../../src/base/components';
import { CronjobContainer } from '../../../src/base/containers/conjobs/Container';

export const Cronjob = () => {
  return (
    <AdminLayout>
      <CronjobContainer />
    </AdminLayout>
  );
};
