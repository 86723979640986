import { Setting, SettingUpdatingBody } from '@models/base';
import { SettingType } from '@pkg/constants';
import { useTrans } from '@pkg/ui';
import { formatDate, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { useQuery } from '@tanstack/react-query';
import { TableColumnsType } from 'antd';
import { useState } from 'react';
import { AdminDataTable } from '../../components';
import { SettingApi } from './api';
import { SettingUpdateDrawer } from './UpdateDrawer';

export const Website = () => {
  const settingApi = SettingApi();
  const { t } = useTrans();
  const [selected, setSelected] = useState<Setting>();

  const { data, refetch } = useQuery<Setting[]>({
    queryKey: ['seting-website'],
    queryFn: async () => await settingApi.getSettings({ type: 'website' as SettingType }).then((res) => res.data || []),
    initialData: [],
  });

  const onUpdate = async (data: SettingUpdatingBody) => {
    if (!selected) return;
    const res = await settingApi.updateSetting(selected._id!, data);
    if (res.error) return showErrorNoti(res.error.message);
    showSuccessNoti(t('updateSuccess'));
    setSelected(undefined);
    refetch();
  };

  const columns: TableColumnsType<Setting> = [
    { title: t('setting.name'), dataIndex: 'name' },
    {
      title: t('setting.value'),
      dataIndex: 'value',
    },
    { title: t('dataTable.createdDate'), render: (row: Setting) => formatDate(row.created), width: 200 },
    { title: t('dataTable.updatedDate'), render: (row: Setting) => formatDate(row.updated), width: 200 },
  ];

  return (
    <>
      <AdminDataTable<Setting>
        data={data}
        pages={{ current: 1, total: 1, limit: 999 }}
        columns={columns}
        onEdit={setSelected}
      />
      {selected && <SettingUpdateDrawer data={selected} onClose={() => setSelected(undefined)} onUpdate={onUpdate} />}
    </>
  );
};
