import { InboxOutlined } from '@ant-design/icons';
import { ImageMimetype } from '@pkg/constants';
import { cn, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { Upload } from 'antd';
import { useTrans } from '../hooks';
import { FileApi } from './api';

const { Dragger } = Upload;

type DraggerUploadProps = {
  onGetFile: (query?: any) => void;
  className?: string;
};

const DraggerUpload = ({ onGetFile, className }: DraggerUploadProps) => {
  const fileApi = FileApi();
  const { t } = useTrans();

  const handleOnChange = async (info: any) => {
    const { status } = info.file;
    if (status === 'done') {
      const formData = new FormData();
      info.file.originFileObj.mimetype = info.file.originFileObj.type;
      formData.append('file', info.file.originFileObj);
      try {
        const res = await fileApi.upload(formData);
        if (res.data) {
          showSuccessNoti(t('file.uploadSuccess'));
          return onGetFile();
        }
        showErrorNoti(res.error.message);
      } catch (error) {}
    } else if (status === 'error') {
      showErrorNoti(t('file.uploadFailed', { filename: info.file.name }));
    }
  };

  const beforeUpload = (file: any) => {
    const isImage = ImageMimetype.includes(file.type);
    if (!isImage) {
      showErrorNoti(t('file.mimetypeError', { mimetype: ImageMimetype.join(', ') }));
      return isImage;
    }
    const maxSize = file.size / 1024 / 1024 < 5;
    if (!maxSize) {
      showErrorNoti(t('file.fileSizeError', { size: 5 }));
      return maxSize;
    }
  };

  return (
    <Dragger
      customRequest={({ onSuccess }) => onSuccess?.('ok')}
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleOnChange}
      accept={ImageMimetype.join(',')}
      className={cn('[&_.ant-upload.ant-upload-btn]:p-4 [&_.ant-upload.ant-upload-drag]:rounded-none', className)}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{t('file.uploadHere')}</p>
    </Dragger>
  );
};

export default DraggerUpload;
