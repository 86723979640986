import { Task, TaskQueryParams } from '@models/base';
import { Module } from '@pkg/config';
import { ITaskStatus, ITaskType } from '@pkg/constants';
import { BreadcrumbItem, useQueryFilter, useTrans } from '@pkg/ui';
import { formatDate, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { useQuery } from '@tanstack/react-query';
import { TableColumnsType } from 'antd';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { SearchBox, Toolbar } from '../../components';
import { AdminDataTable } from '../../components/AdminDataTable';
import { getModule } from '../../helpers/utils';
import { TaskApi } from './api';
import { TargetItemLabel, TaskStatusLabel, taskTypeLabel } from './type';

export const TaskContainer = () => {
  const { t } = useTrans();
  const taskApi = TaskApi();
  const router = useRouter();
  const breadcrumb: BreadcrumbItem[] = [{ title: t('dashboard.title'), href: '/' }, { title: t('task.title') }];
  const [selected, setSelected] = useState<string[]>([]);
  const { filter, setFilter } = useQueryFilter<TaskQueryParams>('tasks', { deleted: false });

  const { data: tasks, refetch } = useQuery({
    queryKey: ['tasks', filter],
    queryFn: () => taskApi.get(filter).then((rs) => rs.data || []),
  });

  const onCreate = () => {
    const module = getModule(router);
    router.push(`/${module}/create`);
  };

  const onUpdate = (row: Task) => {
    const module = getModule(router);
    router.push(`/${module}/${row._id}/edit`);
  };

  const onDuplicate = (row: Task) => {
    const module = getModule(router);
    router.push(`/${module}/${row._id}/duplicate`);
  };

  const onRestore = async (row?: Task) => {
    if (row) {
      const res = await taskApi.restore([row._id]);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('restoreSuccess', { name: row.name }));
    } else {
      const res = await taskApi.restore(selected);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('restoreItemsSuccess', { count: selected.length }));
      setSelected([]);
    }

    refetch();
  };

  const onRemove = async (row?: Task) => {
    if (row) {
      const res = await taskApi.remove([row._id]);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteSuccess', { name: row.name }));
    } else {
      const res = await taskApi.remove(selected);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteItemsSuccess', { count: selected.length }));
      setSelected([]);
    }
    refetch();
  };

  const onDelete = async (row?: Task) => {
    if (row) {
      const res = await taskApi.delete([row._id]);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteSuccess', { name: row.name }));
    } else {
      const res = await taskApi.delete(selected);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteItemsSuccess', { count: selected.length }));
      setSelected([]);
    }
    refetch();
  };

  const columns: TableColumnsType<Task> = [
    { title: t('task.form.fields.name.label'), dataIndex: 'name' },
    {
      title: t('task.form.fields.type.label'),
      dataIndex: 'type',
      render: (data: ITaskType) => t(taskTypeLabel[data] as any),
    },
    {
      title: t('task.form.fields.target.label'),
      dataIndex: 'target',
      render: (data: Module) => {
        const key: any = TargetItemLabel[data];
        if (!key) return data;
        return t(key);
      },
    },
    { title: t('task.form.fields.parent.label'), dataIndex: 'parent', render: (data) => data || '-' },
    {
      title: t('task.form.fields.sourceUrl.label'),
      render: (row: Task) => (
        <a href={row.sourceUrl} target="_blank">
          {row.name}
        </a>
      ),
    },
    {
      title: t('task.form.fields.status.label'),
      dataIndex: 'status',
      render: (data: ITaskStatus) => t(TaskStatusLabel[data] as any),
    },
    { title: t('dataTable.createdDate'), render: (row: Task) => formatDate(row.created) },
  ];

  return (
    <div className="space-y-3">
      <Toolbar
        deleted={filter.deleted}
        items={breadcrumb}
        onCreate={onCreate}
        onRestore={onRestore}
        onRemove={onRemove}
        onDelete={onDelete}
        setFilter={setFilter}
        selected={selected}
        moreActions={<SearchBox onSearch={(keyword) => setFilter({ keyword })} keyword={filter.keyword} />}
      />

      <div className="bg-secondary p-4 h-[calc(100vh-178px)] overflow-y-auto">
        <AdminDataTable<Task>
          data={tasks?.data || []}
          pages={tasks?.pages}
          deleted={filter.deleted}
          columns={columns}
          onEdit={onUpdate}
          onDuplicate={onDuplicate}
          onRestore={onRestore}
          onRemove={onRemove}
          onDelete={onDelete}
          onSelected={setSelected}
          rowsSelected={selected}
        />
      </div>
    </div>
  );
};
